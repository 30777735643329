import styles from './styles.module.css';

const Apertura2Notas = (props) => {
    return (
        <div className={`${styles["Apertura2Notas-parent"]}`}> 
            <div className={`${styles['primer-bloque']}`}>
                {props.renderContainerWidgetCallback(1, "DestacadaInContainers")}
            </div>            
            <div className={`${styles['segundo-bloque']}`}>
                {props.renderContainerWidgetCallback(2, "DestacadaInContainers")}
            </div>
        </div>
    )
}

export default Apertura2Notas;
